<template>
  <div class="page-wrapper">
    <div class="filter white-block" style="padding-left: 1.6rem">
      <div class="filter-item border-right-splite">
        <span class="label">
          <pre>日期范围 :  </pre>
        </span>
        <span class="input">
          <DatePicker
            type="daterange"
            size="small"
            style="width: 230px"
            :startDate="filterOptions.startDate"
            :value="filterOptions.dateRange"
            format="yyyy-MM-dd"
            :editable="false"
            placement="bottom-start"
            @on-change="dateChangeHandler"
          ></DatePicker>
        </span>
      </div>
      <div class="filter-item border-right-splite" v-if="false">
        <span class="label">
          <!-- <pre>{{ isSuperAdmin ? "公司" : "项目" }} :  </pre> -->
          <pre>项目</pre>
        </span>
        <span class="input">
          <Select
            v-model="filterOptions.pm_first_id"
            size="small"
            style="width: 140px"
          >
            <Option
              v-for="item in filterConfigs.project"
              :value="item.value"
              :key="item.value"
              >{{ item.label }}</Option
            >
          </Select>
        </span>
      </div>
      <div class="filter-item border-right-splite" v-show="$public">
        <span class="label">
          <pre>标签 :  </pre>
        </span>
        <span class="input">
          <Select
            v-model="filterOptions.equip"
            :clearable="true"
            size="small"
            style="width: 140px"
            @on-change="choiceEquip"
          >
            <Option
              v-for="item in markList"
              :value="item.mark_id"
              :key="item.mark_id"
              >{{ item.mark_name }}</Option
            >
          </Select>
        </span>
      </div>
      <div class="filter-item border-right-splite">
        <span class="label">
          <pre>设备 :  </pre>
        </span>
        <span class="input">
          <Select
            v-model="filterOptions.equip"
            :clearable="true"
            size="small"
            style="width: 140px"
            @on-change="choiceEquip"
          >
            <Option
              v-for="item in filterConfigs.equipment"
              :value="item.id"
              :key="item.id"
              >{{ item.device_name }}</Option
            >
          </Select>
        </span>
      </div>
      <!-- <div class="filter-item border-right-splite">
        <span class="label">
          <pre>任务 :  </pre>
        </span>
        <span class="input">
          <Select placeholder="可根据任务名称筛选"
                  :clearable=true
                  v-model="filterOptions.taskID"
                  size="small"
                  style="width: 200px"
                  @on-change="choiceTask">
            <Option v-for="item in tasklist"
                    :value="item.id"
                    :key="item.value">{{ item.task_name }}</Option>
          </Select>
        </span>
      </div> -->
      <div class="filter-item border-right-splite">
        <span class="label">
          <pre>用户 :  </pre>
        </span>
        <Input
          v-model="account"
          search
          placeholder="输入用户名查询"
          @on-search="choiceFlyer"
          @on-clear="choiceFlyer"
          clearable
          style="width: 140px"
          size="small"
        />
        <!-- <span class="input">
          <Select placeholder
                  v-model="filterOptions.user_id"
                  size="small"
                  style="width: 200px"
                  @on-change="choiceFlyer">
            <Option v-for="item in filterConfigs.flier"
                    :value="item.user_id"
                    :key="item.user_id">{{ item.name }}</Option>
          </Select>
        </span> -->
      </div>
      <Button  class="filter-item border-right-splite filter-item-labelBut"  @click="getSelectTeam" size="small">选择团队</Button>
      <Button
        class="filter-item border-right-splite filter-item-labelBut"
        type="primary"
        @click="onClickBtn()"
        v-show="$public"
        >添加标签
        <!--  删除  -->
        <div
          class="filter-item-labelBox"
          v-show="showAddLabel"
          @click.stop="doNothing"
        >
          <div class="labelBox-header">
            <div class="labelBox-top-left">创建标签</div>
            <div class="labelBox-top-right" @click="addMark">保存</div>
          </div>
          <div style="display: flex">
            <div
              v-for="(item, index) in circle"
              :key="'circle' + index"
              class="label-main"
              :style="{ background: item }"
              @click="chioceColor(item)"
            ></div>
          </div>

          <div class="label-footer">
            <div class="label-footer-left">
              <div
                class="label-color"
                :style="{ background: circleColor }"
              ></div>
              <input
                type="text"
                class="label-content"
                :readonly="labelName"
                :maxlength="8"
                v-model="markName"
              />
            </div>
            <div class="label-footer-right">
              <!-- <img
                      class="label-footer-redact"
                      src="@/assets/img/Icon/redact.png"
                      alt=""
                      @click="labelName = false"
              />
              <img
                      class="label-footer-close"
                      src="@/assets/img/Icon/close.png"
                      alt=""
                      @click="showAddLabel = false"
              />-->

              <img
                class="label-footer-redact"
                src="@/assets/img/Icon/redact.png"
                alt=""
                @click="addLabelBut"
              />
              <img
                class="label-footer-close"
                src="@/assets/img/Icon/close.png"
                alt=""
                @click="delLabelBut"
              />
            </div>
          </div>
          <div class="label-list-title">标签列表</div>
          <div class="label-list">
            <div
              class="label-list-item"
              v-for="(item, index) in markList"
              :key="'markList' + index"
            >
              <div
                class="label-item-color"
                :style="{ background: item.color }"
              ></div>
              <input class="item-iteml-content" v-model="item.mark_name" />
              <div class="item-item-btn" @click="editMark(item)">保存</div>
              <div class="item-item-btn" @click="deleteMark(item)">删除</div>
            </div>
          </div>
        </div>
      </Button>

      <!-- <div class="filter-item border-right-splite">
        <span class="label">
          <pre>有视频:  </pre>
        </span>
        <span class="input">
          <i-switch v-model="filterOptions.video"
                    @on-change="ifVideoChange" />
        </span>
      </div> -->
      <div class="filter-item border-right-splite" v-if="$CSTJ">
        <Button type="info" @click="exportExcel" :loading="exportLoading"
          >导出表格</Button
        >
      </div>
    </div>
    <div class="content">
      <Spin size="large" v-if="spinShow" fix></Spin>
      <!--飞行轨迹列表-->
      <div v-if="this.filterOptions.equip !== -1">
        <div class="item" v-for="(record, rIndex) in pageData" :key="rIndex">
          <div class="time-title">{{ record.date }}</div>
          <div class="detailBox">
            <div
              class="infoItem"
              v-for="(detail, dIndex) in record.list"
              :key="dIndex"
              @click="intoInfo(detail)"
            >
              <div class="infoMap" :id="'map' + detail.id"></div>
              <div class="infoTitle">
                <div class="left">
                  <h1>{{ detail.DRONEMODEL }}</h1>
                  <h2>
                    <!-- 飞行日期&nbsp;：&nbsp; -->
                    {{ detail.create_time }}
                  </h2>
                  <h2 style="float: right">
                    <!-- 飞行时长&nbsp;：&nbsp; -->
                    {{ secondToDate(detail.fly_time) }}
                  </h2>
                </div>
                <img
                  v-if="detail.vedio.length !== 0"
                  :src="icons.video_icon"
                  @click.stop=""
                  alt=""
                />
              </div>
              <div class="infoContent">
                <div class="top">
                  <div class="left">
                    <h1 class="title">用户</h1>
                    <div class="Text">
                      <p>{{ detail.name }}</p>
                    </div>
                  </div>
                  <div class="right">
                    <h1 class="title">团队</h1>
                    <div class="Text">
                      <p>{{ detail.team_name }}</p>
                    </div>
                  </div>
                </div>
                <!-- 标签功能待做 -->

                <div class="bottom">
                  <!-- <div class="right">
                    <div class="Text">
                      <p v-if="detail.mark.length == 0">暂无标签</p>
                      <div class="markBox"
                           v-else>
                        <Tag @on-close="TagClose(markItem)"
                             v-for="(markItem, markInd) in detail.mark"
                             :key="markInd"
                             :color="markItem.color">{{ markItem.mark_name }}</Tag>
                      </div>
                    </div>
                    <h2 @click.stop="">添加</h2>
                  </div> -->
                  <div class="right" v-show="$public">
                    <h1 class="title">标签</h1>
                    <div class="Text">
                      <p v-if="detail.trackmark.length == 0">暂无标签</p>
                      <div class="markBox" v-else>
                        <Tag
                          @on-close="TagClose(markItem)"
                          v-for="(markItem, markInd) in detail.trackmark"
                          :key="markInd"
                          :color="markItem.color"
                          class="left-tag"
                          >{{ markItem.mark_name }}</Tag
                        >
                      </div>
                      <!-- <Tag closable color="warning" class="left-tag">标签1</Tag> -->
                    </div>
                    <h1
                      @click.stop="addTrackMark(detail)"
                      style="margin-bottom: 5px"
                    >
                      添加
                    </h1>
                    <!--<div @click.stop="">
                      <Select
                        v-model="model9"
                        :label-in-value="true"
                        style="width: 100px"
                        @on-select="chioceMark(rIndex)"
                      >
                        <Option
                          v-for="(item, index) in record.markList"
                          :key="'select' + index"
                          :value="item.mark_id"
                          :label="item.mark_name"
                        >
                          <Tag :color="item.color" class="left-tag">{{
                            item.mark_name
                          }}</Tag>
                        </Option>
                      </Select>
                    </div>-->
                  </div>

                  <div class="left">
                    <h1 class="title">备注</h1>
                    <div class="Text">
                      <p>
                        {{
                          detail.content == null || detail.content == ''
                            ? '暂无备注'
                            : detail.content
                        }}
                      </p>
                    </div>
                    <h2 @click.stop="editModalShou(detail)">
                      <Icon type="md-more" />
                    </h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="no-data" v-if="!pageData.length">
          <img :src="noData" alt="" />
          暂无数据
        </div>
        <div class="paging" v-if="total > 10">
          <Page
            :total="total"
            :page-size="limit"
            :current="parseInt(page)"
            size="small"
            show-total
            :page-size-opts="[10, 15, 20]"
            @on-page-size-change="pageSizeChange"
            @on-change="changePage"
          />
        </div>
      </div>
      <!--执法记录仪轨迹列表-->
      <div
        v-if="this.filterOptions.equip === -1"
        class="item"
        style="margin-top: 0.2rem"
      >
        <div class="detailBox">
          <div
            class="infoItem"
            v-for="(detail, dIndex) in pageData"
            :key="dIndex"
            @click="intoInfo(detail)"
          >
            <div class="infoMap" :id="'map' + detail.id"></div>
            <div class="infoTitle">
              <div class="left">
                <h1>{{ detail.DRONEMODEL }}</h1>
                <h2 style="float: right">
                  <!-- 执行时间&nbsp;：&nbsp; -->
                  {{ detail.start_time }}
                </h2>
              </div>
            </div>
            <div class="infoContent">
              <div class="top">
                <div class="left">
                  <!-- <h1>执行人</h1> -->
                  <div class="Text">
                    <p>{{ detail.name }}</p>
                  </div>
                </div>
                <div class="right">
                  <!-- <h1>团队</h1> -->
                  <div class="Text">
                    <p>{{ detail.team_name }}</p>
                  </div>
                </div>
              </div>
              <!-- 标签功能待做 -->

              <div class="bottom">
                <!-- <div class="left">
                    <div class="Text">
                      <p v-if="detail.mark.length == 0">暂无标签</p>
                      <div class="markBox"
                           v-else>
                        <Tag @on-close="TagClose(markItem)"
                             v-for="(markItem, markInd) in detail.mark"
                             :key="markInd"
                             :color="markItem.color">{{ markItem.mark_name }}</Tag>
                      </div>
                    </div>
                    <h2 @click.stop="">添加</h2>
                  </div> -->
                <div class="right">
                  <h1>备注</h1>
                  <div class="Text">
                    <p>
                      {{
                        detail.content == null || detail.content == ''
                          ? '暂无备注'
                          : detail.content
                      }}
                    </p>
                  </div>
                  <h2 @click.stop="editModalShou(detail)">
                    <Icon type="md-more" />
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="no-data" v-if="!pageData.length">
          <img :src="noData" alt="" />暂无数据
        </div>
        <div class="paging" v-if="total > 10">
          <Page
            :total="total"
            :page-size="limit"
            :current="parseInt(page)"
            size="small"
            show-sizer
            show-total
            :page-size-opts="[10, 15, 20]"
            @on-page-size-change="pageSizeChange"
            @on-change="changePage"
          />
        </div>
      </div>
      <!--modal-->
      <Modal
        v-model="showModal"
        class="modal"
        width="800"
        :footer-hide="true"
        @on-cancel="cancelVideoHandler"
      >
        <div slot="header">
          <span class="video-flier">
            <pre>团队 : </pre>
            <span>{{ videoData.flier }}</span>
          </span>
          <span class="video-time">{{ videoData.time }}</span>
        </div>
        <video
          v-if="showModal"
          ref="video"
          :src="videoData.videoUrl"
          controls="controls"
        >
          您的浏览器不支持 video 标签。
        </video>
      </Modal>
      <!-- 编辑备注 -->
      <Modal v-model="editModal" class-name="editProject-center-modal">
        <p slot="header" style="color: #409eff; text-align: center">
          <span>编辑备注</span>
        </p>
        <div style="padding: 0.1rem 0.3rem">
          <Input
            v-model="RemarksValue"
            type="textarea"
            :autosize="{ minRows: 2, maxRows: 5 }"
          />
        </div>
        <div style="text-align: center" slot="footer">
          <Button
            type="info"
            @click="editRemarks"
            style="width: 200px; letter-spacing: 8px"
            >确认</Button
          >
        </div>
      </Modal>
    </div>
    <Modal
      class="cb-list-box"
      v-model="showCkbList"
      title="选择标签"
      @on-cancel="closeLsitMark"
    >
      <div>
        <CheckboxGroup @on-change="addListMarkBut" v-model="MarkTotalIDList">
          <div class="cb-box" v-for="(item, index) in markList" :key="index">
            <Checkbox :label="item.mark_id" border>
              <span
                class="label-color-box"
                :style="{ background: item.color }"
              ></span>
              <span>{{ item.mark_name }}</span>
            </Checkbox>
          </div>
        </CheckboxGroup>
      </div>
      <template slot="footer">
        <Button type="primary" @click="addMarkCheckbut">确定</Button>
        <Button @click="closeLsitMark">取消</Button>
      </template>
    </Modal>
  </div>
</template>

<script>
import AirPlane from '@/assets/img/statistics/air-plane.png'
import reTeam from '@/assets/img/statistics/re-team.png'
import reProject from '@/assets/img/statistics/re-project.png'
import reTimeCount from '@/assets/img/statistics/re-timecount.png'
import reLocation from '@/assets/img/statistics/re-location.png'
import rePeople from '@/assets/img/statistics/re-people.png'
import reTime from '@/assets/img/statistics/re-time.png'
import reHeight from '@/assets/img/statistics/re-height.png'
import video_icon from '@/assets/img/Icon/video_icon.png'
import Video from '@/assets/img/video/video.mp4'
import noData from '@/assets/img/noData.png'

import _ from 'lodash'
import Api from '@/utils/api.js'
import filterMixin from '@/utils/filter.mixin.js'
import { saveAs } from 'file-saver'
export default {
  name: 'records',
  mixins: [filterMixin],
  data() {
    return {
      markData: {},
      markList: [],
      newMarkName: '',
      markName: '',
      model9: '',
      showAddLabel: false,
      labelName: true,
      circle: [
        '#ff6d60',
        '#fed756',
        '#4d98ff',
        '#66d86f',
        '#a5a5a9',
        '#c17ae5',
      ],
      circleColor: '#ff6d60',
      userInfo: {},
      filterUpdateLock: true, // 页面初始化的时候需要锁住不让相关变量被赋值的时候产生更新导致页面重新请求数据 等页面加载完成之后就可以了
      showModal: false,
      spinShow: true,
      filterOptions: {
        startDate: this.getLastMonthDate(),
        dateRange: ['', ''],
        // dateRange: ["1970-01-01", this.$moment().format("YYYY-MM-DD")],
        pm_first_id: -1,
        /*team_id: -1,*/
        equip: undefined,
        user_id: -1,
        video: false,
        taskID: '',
        team_id: -1,//团队id
      },
      icons: {
        reTeam,
        reProject,
        reTimeCount,
        reLocation,
        rePeople,
        reTime,
        reHeight,
        video_icon,
      },
      noData,
      curClickRowIndex: -1,
      curClickRowDetailIndex: -1,
      curClickBtnIndex: -1,
      btnLoading: [false, false, false],
      filterConfigs: {
        project: [],
        team: [],
        equipment: [],
        // flier: [],
      },
      videoData: {
        flier: '',
        time: '',
        videoUrl: '',
      },
      pageData: [],
      operationList: [
        {
          text: '视频',
          type: 'primary',
        },
        {
          text: '查看',
          type: 'default',
        },
        {
          text: '导出',
          type: 'default',
        },
      ],
      planeIcon: AirPlane,
      video: Video,
      //分頁
      page: 1,
      limit: 10,
      current: 1,
      total: 0,
      // 表格
      hostClass: 'spread-host',
      lawData: [],
      //分頁
      pageLaw: 1,
      limitLaw: 10,
      currentLaw: 1,
      totalLaw: 0,
      editModal: false,
      editValue: {},
      RemarksValue: '',
      //   tasklist: [],
      account: '', // 用户名模糊查询
      exportLoading: false,
      pageListId: '', //获取列表ID
      MarkTotalIDList: [], // 列表标签ID
      showCkbList: false,
      searchMarkId: '', //搜索ID
      TeamList:[],//团队
    }
  },
  computed: {
    filterDateRange: {
      get() {
        return {
          start_time: this.dateRange[0] || '',
          end_time: this.dateRange[1] || this.$moment().format('YYYY-MM-DD'),
        }
      },
    },
    isvedio: {
      get() {
        return true
      },
    },
    // isSuperAdmin() {
    //   return (
    //     JSON.parse(window.sessionStorage.getItem('user_info') || '{}').type ===
    //     0
    //   );
    // },

    fetchParams: {
      get() {
        let page = sessionStorage.getItem('page').toString()
        this.page = page
        return {
          equip: JSON.stringify(this.filterOptions.equip),
          start_time: this.filterOptions.dateRange[0] || '',
          end_time:
            this.filterOptions.dateRange[1] ||
            this.$moment().format('YYYY-MM-DD'),
          team_id: this.filterOptions.team_id,

          //   ...(this.filterOptions.team_id &&
          //   parseInt(this.filterOptions.team_id) >= 0
          //     ? { team_id: this.filterOptions.team_id }
          //     : { team_id: this.userInfo.team_id }),

          ...(this.filterOptions.user_id &&
          parseInt(this.filterOptions.user_id) >= 0
            ? { user_id: this.filterOptions.user_id }
            : {}),
          page: this.page,
          limit: this.limit,
          task_id: this.filterOptions.taskID,
          name: this.account,
          mark_id: this.searchMarkId,
        }
      },
    },
  },
  mounted() {
    this.userInfo = JSON.parse(window.sessionStorage.getItem('user_info'))
    if (sessionStorage.getItem('team_id') === null) {
      this.filterOptions.team_id = this.userInfo.team_id
      this.fetchConfig()
    } else {
      this.filterOptions.team_id = Number(sessionStorage.getItem('team_id'))
      this.fetchConfig()
    }
    this.$_bus.$off('change_Team').$on('change_Team', (value) => {
      this.page = 1
      sessionStorage.setItem('page', 1) // 设置初始翻页
      this.filterOptions.team_id = value
      this.fetchConfig()
    })
    if (this.$public) {
      this.getMarkToTrack()
    }
  },
  methods: {
    //选择团队
    getSelectTeam(){
      this.$store.commit('setTeamValue',true)
    /*  console.log( this.$store.state.teamValue)*/
    },
    //列表选择添加标签
    addMarkCheckbut() {
      let data = {
        // markName: this.markData.label,
        mark_id:
          this.MarkTotalIDList.length === 0 ? null : this.MarkTotalIDList,
        track_id: this.pageListId,
      }
      this.$post(Api.addMarkToTrack(), data).then((res) => {
        if (res.code === 1) {
          this.$Message.success('修改标签成功')
          this.getMarkToTrack()
          this.MarkTotalIDList = []
          this.showCkbList = false
          this.fetchData()
        } else {
          this.$Message.error('修改标签失败')
        }
      })
    },
    //列表关闭标签
    closeLsitMark() {
      this.showCkbList = false
      this.MarkTotalIDList = []
    },
    //选择标签ID
    addListMarkBut(e) {
      if (e.length >= 6) {
        this.$Message.error('选择标签最多5个')
        e.splice(e.length - 1, 1)
        return
      } else {
        this.MarkTotalIDList = e
      }
    },
    /* 关闭新增弹窗 */
    closeAdd() {
      this.showAddLabel = false
      this.markList = []
    },
    /* 新增标签 */
    addLabelBut() {
      this.labelName = false
      /* this.markList=[...this.markList,{markName:this.markName}]
       this.markName=''*/
    },

    /* 删除标签清空 */
    delLabelBut() {
      this.showAddLabel = false
      /* this.markName=''*/
    },
    addMark() {
      if (this.markName === '') {
        this.$Message.error('标签名不能为空')
        return
      }
      this.$post(Api.addMark(), {
        color: this.circleColor,
        markName: this.markName,
        team_id:
          this.userInfo.permission == 0
            ? this.userInfo.team_id
            : this.filterOptions.team_id,
      }).then((res) => {
        if (res.code === 1) {
          this.$Message.success(res.msg_customer)
          /*this.showAddLabel = false*/
          this.markName = ''
          this.getMarkToTrack()
        } else {
          this.$Message.error(res.msg_customer)
        }
      })
    },
    editMark(item) {
      if (item.mark_name == '') {
        this.$Message.error('新标签名不能为空')
        return
      }
      this.$post(Api.updateMarkName(), {
        mark_id: item.mark_id,
        markName: item.mark_name,
      }).then((res) => {
        if (res.code === 1) {
          this.$Message.success(res.msg_customer)
          this.getMarkToTrack()
        } else {
          this.$Message.error(res.msg_customer)
        }
      })
    },
    addTrackMark(detail) {
      this.showCkbList = true
      console.log('666660', detail)
      this.pageListId = detail.id
      // 点击添加获取标签列表
      this.MarkTotalIDList = []

      detail.trackmark.forEach((e) => {
        this.MarkTotalIDList = [...this.MarkTotalIDList, e.mark_id]
      })
    },
    getMarkToTrack() {
      this.$post(Api.getMarkToTrack(), {}).then((res) => {
        if (res.code === 1) {
          this.markList = res.data
          console.log('1111111', this.markList)
        }
      })
    },
    deleteMark(item) {
      /*this.markList.splice(index,1)*/
      this.$post(Api.deleteMark(), {
        mark_id: item.mark_id,
        markName: item.mark_name,
      }).then((res) => {
        if (res.code === 1) {
          this.$Message.success(res.msg_customer)
          this.getMarkToTrack()
        } else {
          this.$Message.error(res.msg_customer)
        }
      })
    },
    chioceMark(index, e) {
      console.log('=====', index, e)
      // this.markData = e
    },
    doNothing() {},
    onClickBtn() {
      this.showAddLabel = !this.showAddLabel
    },
    chioceColor(item) {
      this.circleColor = item
    },

    // 下载
    saveAs: saveAs,
    //  当前日期的前一个月
    getLastMonthDate() {
      return new Date(new Date().getTime() - 30 * 24 * 3600 * 1000)
    },
    // 地图搜索地点然后定位到这里
    setLocationByAMap() {
      let _this = this
      // 引入瓦片图-平面
      if (this.$online) {
        const map = new AMap.Map('container', {
          resizeEnable: true,
        })
      } else {
        const map = new AMap.Map('container', {
          resizeEnable: true,
          layers: [
            new AMap.TileLayer({
              getTileUrl: function (x, y, z) {
                return Api.GaoDeStreetMap() + z + '/' + x + '/' + y + '.png'
              },
              zIndex: 10,
            }),
          ],
        })
      }
      // 修改主题样式
      //   if (this.$online) {
      //     this.map.setMapStyle('amap://styles/whitesmoke');
      //   }

      const geocoder = new AMap.Geocoder({
        city: '010', //城市设为北京，默认：“全国”
        radius: 1000, //范围，默认：500
      })
      const regeoCode = function (pos, ele) {
        geocoder.getAddress(pos, function (status, result) {
          if (status === 'complete' && result.regeocode) {
            _this.$set(ele, 'startPlace', result.regeocode.formattedAddress)
          } else {
            this.$notice.error({
              title: '根据经纬度查询地址失败',
            })
          }
        })
      }
      this.pageData.forEach((ele) => {
        ele.list.forEach((e) => {
          let position = [
            parseFloat(e.HomeLongitude),
            parseFloat(e.HomeLatitude),
          ]
          regeoCode(position, e)
        })
      })
    },
    // 日期改变后触发请求数据
    dateChangeHandler(e) {
      this.page = 1
      sessionStorage.setItem('page', 1) // 设置初始翻页
      this.filterOptions.dateRange = e
      this.fetchData()
    },
    // 选择任务
    choiceTask(e) {
      // this.$store.commit('saveTask',e);
      // this.$store.commit('savePage',1);
      this.page = 1
      sessionStorage.setItem('page', 1) // 设置初始翻页
      this.filterOptions.taskID = e
      this.fetchData()
    },
    // 选择飞手
    choiceFlyer() {
      // this.$store.commit('saveFlyer',e);
      // this.$store.commit('savePage',1);
      this.page = 1
      sessionStorage.setItem('page', 1) // 设置初始翻页
      this.fetchData()
    },
    // 选择设备
    choiceEquip(e) {
      console.log('2323232', e)
      this.page = 1
      sessionStorage.setItem('page', 1) // 设置初始翻页
      this.searchMarkId = this.filterOptions.equip = e
      this.fetchData()
    },

    // 选择团队
    sreachTeam(e) {
      this.page = 1
      sessionStorage.setItem('page', 1) // 设置初始翻页
      sessionStorage.setItem('team_id', e);
      this.filterOptions.team_id = e
      this.fetchData()
    },
    // 列表数据1
    fetchConfig() {
      this.spinShow = true
      this.$post(Api.getTrackListsConfig(), {
        team_id:
          this.userInfo.permission == 0
            ? this.userInfo.team_id
            : this.filterOptions.team_id,
        //   this.filterOptions.team_id == -1
        //     ? this.userInfo.team_id
        //     : this.filterOptions.team_id,
      })
        .then((res) => {
          if (res.code === 1) {
            // res.data.devicelist.unshift({ id: '', device_name: '请选择' });
            this.filterConfigs.equipment = res.data.devicelist
            // 增加执行记录仪
            this.filterConfigs.equipment.push({
              id: -1,
              device_name: '执法记录仪',
            })
            // this.tasklist = res.data.tasklist;
            // this.filterConfigs.flier = res.data.flyuserlist;

            // this.filterOptions.equip = this.$store.state.device_id;
            // this.filterOptions.user_id = this.$store.state.flyer_id;
            // this.filterOptions.taskID = this.$store.state.task_id;

            this.fetchData()
          } else {
            this.spinShow = false
            this.filterUpdateLock = false
            this.$notice.error({
              title: '视频记录列表配置接口异常,返回无数据1',
            })
          }
        })
        .catch((err) => {
          this.spinShow = false
          this.filterUpdateLock = false
          console.log(err)
          this.$notice.error({
            title: '视频记录列表配置接口异常,返回无数据2!',
            desc: err.toString(),
          })
        })
    },
    // 根据配置来获取数据
    fetchData() {
      this.spinShow = true
      this.$post(Api.getTrackLists(), _.merge({}, this.fetchParams))
        .then((res) => {

          if (res.code == 1) {
            this.pageData = res.data.data
            this.total = res.data.total
            setTimeout(() => {
              this.creationMap() //加载地图
            }, 400)
          } else {
            this.pageData = []
            this.spinShow = false
            this.filterUpdateLock = false
            this.$notice.error({
              title: '视频记录列表接口异常,返回无数据3',
            })
          }
        })
        .catch((err) => {
          console.log(err)
          this.$notice.error({
            title: '视频记录列表接口异常,返回无数据2',
            desc: err.toString(),
          })
        })
        .finally(() => {
          this.filterUpdateLock = false
          this.spinShow = false
        })
    },
    // 下载
    download(href, title = '视频文件') {
      this.saveAs(href, title)
    },
    // 3个按钮的事件
    btnHandler(index, dIndex, rIndex, ele, detail) {
      console.log(index, dIndex, rIndex, ele, detail)
      this.curClickRowIndex = rIndex
      this.curClickRowDetailIndex = dIndex
      this.curClickBtnIndex = index
      if (ele.text === '视频') {
        if (!(detail.video || detail.vedio)) return
        this.$set(this.btnLoading, index, true)
        this.videoData.time = detail.create_time
        this.videoData.flier = detail.name
        this.videoData.videoUrl = detail.video || detail.vedio || this.video
        this.showModal = true
      }
      if (ele.text === '查看') {
        this.$set(this.btnLoading, index, true)
        this.$router.push({
          path: '/statistics/trajectory-playback',
          query: {
            track_id: detail.id || -1,
          },
        })
      }
      if (ele.text === '导出') {
        if (!(detail.video || detail.vedio)) return
        this.$set(this.btnLoading, index, true)
        this.download(detail.video || detail.vedio)
        setTimeout(() => {
          this.$set(this.btnLoading, index, false)
        }, 3000)
      }
    },
    // 取消播放视频
    cancelVideoHandler() {
      this.$set(this.btnLoading, 0, false)
      this.$refs[`video`].pause()
    },
    //更改页面  无人机
    changePage(ind) {
      this.page = ind
      sessionStorage.setItem('page', ind)
      this.fetchData()
    },
    pageSizeChange(l) {
      this.limit = l
      this.changePage(1)
    },
    //更改页面  执法仪
    changeLaw(ind) {
      this.pageLaw = ind
      sessionStorage.setItem('page', ind)
      this.fetchData()
    },
    //
    formatSeconds(value) {
      var secondTime = parseInt(value) // 秒
      var minuteTime = 0 // 分
      var hourTime = 0 // 小时
      var dayTime = 0 // 天
      var result = ''
      if (value < 60) {
        result = secondTime + '秒'
      } else {
        if (secondTime >= 60) {
          // 如果秒数大于60，将秒数转换成整数
          // 获取分钟，除以60取整数，得到整数分钟
          minuteTime = parseInt(secondTime / 60)
          // 获取秒数，秒数取佘，得到整数秒数
          secondTime = parseInt(secondTime % 60)
          // 如果分钟大于60，将分钟转换成小时
          if (minuteTime >= 60) {
            // 获取小时，获取分钟除以60，得到整数小时
            hourTime = parseInt(minuteTime / 60)
            // 获取小时后取佘的分，获取分钟除以60取佘的分
            minuteTime = parseInt(minuteTime % 60)
            if (hourTime >= 24) {
              // 获取天数， 获取小时除以24，得到整数天
              dayTime = parseInt(hourTime / 24)
              // 获取小时后取余小时，获取分钟除以24取余的分；
              hourTime = parseInt(hourTime % 24)
            }
          }
        }
        if (secondTime > 0) {
          secondTime =
            parseInt(secondTime) >= 10 ? secondTime : '0' + secondTime
          result = '' + secondTime + '秒'
        }
        if (minuteTime > 0) {
          minuteTime =
            parseInt(minuteTime) >= 10 ? minuteTime : '0' + minuteTime
          result = '' + minuteTime + '分' + result
        }
        if (hourTime > 0) {
          result = '' + parseInt(hourTime) + '小时' + result
        }
        if (dayTime > 0) {
          result = '' + parseInt(dayTime) + '天' + result
        }
      }
      return result
    },
    //导出的方法
    exportExcel() {
      this.exportLoading = true
      this.$post(Api.trackExcelExport(), _.merge({}, this.fetchParams))
        .then((res) => {
          console.log(res)
          this.exportLoading = false

          window.open(res.data.path)
        })
        .catch(() => {
          this.exportLoading = false
        })
    },

    ifVideoChange(val) {
      this.page = 1
      sessionStorage.setItem('page', 1) // 设置初始翻页
      this.fetchConfig()
    },
    //秒转时分秒
    secondToDate(result) {
      var h =
        Math.floor(result / 3600) < 10
          ? '0' + Math.floor(result / 3600)
          : Math.floor(result / 3600)
      var m =
        Math.floor((result / 60) % 60) < 10
          ? '0' + Math.floor((result / 60) % 60)
          : Math.floor((result / 60) % 60)
      var s =
        Math.floor(result % 60) < 10
          ? '0' + Math.floor(result % 60)
          : Math.floor(result % 60)
      return (result = h + ':' + m + ':' + s)
    },
    //关闭标签(未对接口)
    TagClose(event) {
      console.log(event)
      this.$post('', {
        client: 'web',
      }).then((res) => {
        console.log(res)
        if (res.code == 1) {
        } else {
          this.$Message.error(res.msg_customer)
        }
      })
    },
    //进入轨迹详情
    intoInfo(detail) {
      if (this.filterOptions.equip == '-1') {
        this.$router.push({
          path: '/statistics/LawPlay',
          query: {
            track_id: detail.id || -1,
          },
        })
      } else {
        this.$router.push({
          path: '/statistics/trajectory-playback',
          query: {
            track_id: detail.id || -1,
          },
        })
      }
    },
    //创建地图,显示航线
    creationMap() {
      if (this.filterOptions.equip === -1) {
        // 执法记录仪轨迹
        this.pageData.forEach((detail, rIndex) => {
          // 创建地图
          var ID = 'map' + detail.id
          if (this.$online) {
            var map = new AMap.Map(ID, {
              dragEnable: false,
              resizeEnable: true,
              expandZoomRange: true,
              center: this.$Center,
            })
          } else {
            var map = new AMap.Map(ID, {
              dragEnable: false,
              resizeEnable: true,
              expandZoomRange: true,
              center: this.$Center,
              layers: [
                new AMap.TileLayer({
                  getTileUrl: function (x, y, z) {
                    return Api.GaoDeStreetMap() + z + '/' + x + '/' + y + '.png'
                  },
                  zIndex: 10,
                }),
              ],
            })
          }
          let path = []
          detail.local_data_list.forEach((e) => {
            path.push([e.lng, e.lat])
          })

          let polyline = new AMap.Polyline({
            path: path,
            strokeColor: '#3366FF',
            strokeOpacity: 1,
            strokeWeight: 4,
            showDir: true,
            // 折线样式还支持 'dashed'
            strokeStyle: 'solid',
            // strokeStyle是dashed时有效
            strokeDasharray: [10, 5],
            lineJoin: 'round',
            lineCap: 'round',
            zIndex: 50,
          })

          polyline.setMap(map)
          // 缩放地图到合适的视野级别
          map.setFitView([polyline])
        })
      } else {
        // 飞机轨迹
        this.pageData.forEach((record, rIndex) => {
          record.list.forEach((detail, dIndex) => {
            // 创建地图
            var ID = 'map' + detail.id
            if (this.$online) {
              var map = new AMap.Map(ID, {
                dragEnable: false,
                resizeEnable: true,
                expandZoomRange: true,
                center: this.$Center,
              })
            } else {
              var map = new AMap.Map(ID, {
                dragEnable: false,
                resizeEnable: true,
                expandZoomRange: true,
                center: this.$Center,
                layers: [
                  new AMap.TileLayer({
                    getTileUrl: function (x, y, z) {
                      return (
                        Api.GaoDeStreetMap() + z + '/' + x + '/' + y + '.png'
                      )
                    },
                    zIndex: 10,
                  }),
                ],
              })
            }

            let path = JSON.parse(detail.data)

            let polyline = new AMap.Polyline({
              path: path,
              strokeColor: '#3366FF',
              strokeOpacity: 1,
              strokeWeight: 4,
              showDir: true,
              // 折线样式还支持 'dashed'
              strokeStyle: 'solid',
              // strokeStyle是dashed时有效
              strokeDasharray: [10, 5],
              lineJoin: 'round',
              lineCap: 'round',
              zIndex: 50,
            })

            polyline.setMap(map)
            // 缩放地图到合适的视野级别
            map.setFitView([polyline])
          })
        })
      }
    },
    editModalShou(val) {
      this.editModal = true
      this.editValue = val
      this.RemarksValue = val.content
    },
    //备注编辑
    editRemarks() {
      this.$post(Api.updateRemark(), {
        id: this.editValue.id,
        content: this.RemarksValue,
      }).then((res) => {
        if (res.code == 1) {
          this.RemarksValue = ''
          this.fetchData()
          this.editModal = false
        } else {
          this.$Message.error(res.msg_customer)
        }
      })
    },
    // closeEdit(){
    //   console.log('aaa')
    // }
  },
}
</script>

<style lang="scss" scoped>
.modal {
  /deep/ .ivu-modal-content {
    border-radius: 0.1rem;
    .ivu-modal-header {
      color: $white;
      border-radius: 0.1rem 0.1rem 0 0;
      background-color: $black;
      width: 100%;

      > div {
        display: flex;
        flex: 1;
        position: relative;
      }

      .video-flier {
        position: absolute;
        top: 0;
        left: 0.3rem;
        width: auto;
        white-space: nowrap;

        pre {
          display: inline-block;
        }
      }

      .video-time {
        flex: 1;
        text-align: center;
      }
    }

    .ivu-modal-body {
      padding: 0;
      background-color: #000;
      border-radius: 0 0 0.1rem 0.1rem;
    }

    .ivu-modal-close .ivu-icon-ios-close {
      top: -0.03rem;
    }

    video {
      width: 100%;
      height: 7rem;
    }
  }
}
/deep/ .ivu-input-wrapper {
  width: 230px !important;
}

.page-wrapper {
  width: 100%;
  height: 100%;
  margin: 0.2rem;
  background-color: $xf_hei5;
  padding: 0.1rem;
  display: flex;
  flex-direction: column;
  position: relative;

  .filter {
    // position: relative;
    display: flex;
    align-items: center;
    padding-top: 10px;
    flex-wrap: wrap;
    margin-bottom: 10px;
    flex-wrap: nowrap;
    // overflow-y: auto;
    max-width: 100%;

    .filter-item {
      // position: relative;
      display: flex;
      align-items: center;
      padding: 0 25px;
      margin-bottom: 10px;
      line-height: 1;
      &:last-child {
        border-right: none;
      }

      .label {
        white-space: pre;
        font-size: 14px;
        color: $white;
      }

      .btn {
        border: 1px solid rgba(220, 222, 226, 1);
        margin-right: 20px;

        &:last-child {
          margin-right: 0;
        }
      }

      .ivu-select-item {
        padding: 7px 16px;
      }
    }
    .filter-item-labelBut {
      position: relative;
      border-radius: 2px;
      margin-left: 50px;
      .filter-item-labelBox {
        width: 300px;
        position: absolute;
        z-index: 999;
        top: 43px;
        right: 0px;
        // width: 258px;
        // height: 145px;
        padding: 20px 27px 20px 16px;
        background: #fff;

        .labelBox-header {
          display: flex;
          justify-content: space-between;
          margin-bottom: 20px;
          .labelBox-top-left {
            color: #999;
          }
          .labelBox-top-right {
            color: #4c98ff;
          }
        }

        .label-main {
          width: 20px;
          height: 20px;
          border-radius: 50%;
          margin-bottom: 26px;
          margin-right: 10px;
        }
        .label-footer {
          display: flex;
          justify-content: space-between;
          .label-footer-right {
            display: flex;
            justify-content: center;
            align-items: center;
            .label-footer-redact {
              width: 14.5px;
              height: 14.5px;
              margin-right: 7px;
            }
            .label-footer-close {
              width: 11px;
              height: 11px;
            }
          }
          .label-footer-left {
            display: flex;
            justify-content: center;
            align-items: center;
            .label-color {
              width: 16px;
              height: 16px;
              margin-right: 16px;
            }
            .label-content {
              width: 140px;
              padding: 2px 5px;
              font-size: 16px;
              color: #000;
              border-radius: 2px;
              border: 1px solid #a5a5a9;
            }
          }
        }
        .label-list-title {
          float: left;
          color: #999;
          margin-top: 10px;
        }

        .label-list {
          width: 100%;
          // text-align: left;
          color: #999;
          height: 120px;
          margin-top: 5px;
          overflow: auto;

          .label-list-item {
            display: flex;
            // justify-content: space-between;
            align-items: center;
            width: 100%;
            margin-top: 10px;

            .label-item-color {
              flex-shrink: 0;
              width: 16px;
              height: 16px;
              margin-right: 16px;
            }

            .item-iteml-content {
              width: 140px;
              height: 27px;
              padding: 2px 5px;
              font-size: 16px;
              color: #000;
              border-radius: 2px;
              border: 1px solid #a5a5a9;
            }

            .item-item-btn {
              flex-shrink: 0;
              margin-left: 10px;
              font-size: 12px;
              line-height: 1;
            }

            // .item-left {
            //   display: flex;
            //   justify-content: center;
            //   align-content: center;
            //   .item-left-color {
            //     width: 16px;
            //     height: 16px;
            //     margin-right: 16px;
            //   }
            //   .item-left-content {
            //     width: 140px;
            //     height: 27px;
            //     padding: 2px 5px;
            //     font-size: 16px;
            //     color: #000;
            //     border-radius: 2px;
            //     border: 1px solid #a5a5a9;
            //   }
            // }
            // .item-right {
            //   display: flex;
            //   justify-content: center;
            //   align-content: center;
            //   margin-left: 10px;
            //   .item-right-btn {
            //     font-size: 12px;
            //     line-height: 1;
            //   }
            // }
          }
        }
      }
    }
  }
  .filter::-webkit-scrollbar {
    display: none;
  }
  .content {
    flex: 1;
    width: 100%;
    // position: absolute;
    padding: 0 2.4rem 0.9rem;
    // top: 60px;
    // bottom: 0;
    // left: 0;
    overflow: auto;
    background-color: $xf_hei5;
    .item {
      width: 100%;
      .time-title {
        font-size: 14px;
        padding: 20px 25px;
        color: $font_color_1;
      }
      .detailBox {
        margin-bottom: 20px;
        display: flex;
        flex-wrap: wrap;
        .infoItem {
          margin: 0 15px 30px;
          width: 242px;
          // height: 418px;
          //   height: 330px;
          background: $xf_hei1;
          color: $font_color_1;
          box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
          border-radius: 2px;
          cursor: pointer;
          .infoMap {
            width: 100%;
            height: 118px;
            position: relative;
            /*background-color: #dedede;*/
            // h1 {
            //   font-size: 12px;
            //   display: block;
            //   width: 57px;
            //   height: 22px;
            //   line-height: 22px;
            //   text-align: center;
            //   position: absolute;
            //   right: 10px;
            //   bottom: 6px;
            //   background: #000000;
            //   opacity: 0.5;
            //   color: #ffffff;
            //   z-index: 99999;
            // }
          }
          .infoTitle {
            width: 100%;
            height: 60px;
            border-bottom: 1px solid #e6e6e6;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 10px;
            .left {
              width: 100%;
              h1 {
                font-size: 14px;
                font-weight: 600;
                line-height: 20px;
                // color: $font_color_1;
                // color: #333333;
              }
              h2 {
                display: inline-block;
                font-size: 12px;
                font-weight: 400;
                line-height: 17px;
                // color: $font_color_1;
                // color: #333333;
              }
            }
            > img {
              width: 28px;
              margin-left: 3px;
            }
          }
          .infoContent {
            padding: 10px 10px 0 10px;
            > div {
              width: 100%;
              display: flex;
              justify-content: space-between;
              margin-bottom: 20px;
            }
            .top {
              > div {
                width: 145px;
                h1 {
                  font-size: 12px;
                  font-weight: 400;
                  line-height: 17px;
                  //   color: #999999;
                  margin-bottom: 4px;
                }

                .Text {
                  p {
                    font-size: 12px;
                    font-weight: 400;
                    line-height: 17px;
                    // color: #333333;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                  }
                }
              }
            }
            .bottom {
              > div {
                width: 100%;
                // height: 40px;
                position: relative;
                h1 {
                  font-size: 12px;
                  font-weight: 400;
                  line-height: 17px;
                  //   color: #999999;
                }
                .Text {
                  width: 80%;
                  margin-bottom: 4px;
                  p {
                    font-size: 12px;
                    font-weight: 400;
                    line-height: 17px;
                    // color: #333333;
                  }
                  .left-tag {
                    height: 18px;
                    padding: 2px 2px;
                    line-height: 1;
                  }
                }
                h2 {
                  font-size: 25px;
                  font-weight: 400;
                  line-height: 15px;
                  //   color: $xf_hui3;
                  position: absolute;
                  right: -10px;
                  top: 20px;
                }
              }
              .left {
                .Text {
                  height: 46px;
                  overflow: auto;
                }
                .Text::-webkit-scrollbar {
                  display: none;
                }
              }
              .left {
                .Text {
                  p {
                    word-break: break-all;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 2;
                    overflow: hidden;
                  }
                }
              }
            }
            .title {
              color: #999;
              margin-bottom: 4px;
            }
          }
        }
      }
    }
    .no-data {
      font-size: 16px;
      text-align: center;
      // margin: 50px 0;
      // padding-bottom: 2rem;
      img {
        margin: 1rem auto 0.2rem;
      }
    }
    .paging {
      margin: 20px 0;
      width: 100%;
      height: auto;
      display: flex;
      justify-content: center;
    }
    .Excel {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      margin-bottom: 10px;
    }
  }
}

.white-block {
  background-color: $xf_hui2;
}
.cb-list-box {
  /deep/ .ivu-modal-content {
    background-color: white !important;
  }
  /deep/ .ivu-modal-header-inner {
    color: #000000 !important;
  }
  .cb-box {
    margin: 10px;
    display: inline-block;
    .label-color-box {
      width: 16px;
      height: 16px;
      margin: 0 10px;
      display: inline-block;
      vertical-align: -3px;
    }
  }
}
</style>
